<template>
  <b-sidebar
    id="element-sidebar"
    :visible="isToggleSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-form-group
            label="Tipo de Flota"
            label-for="tipoFlota"
          >
            <v-select
              id="tipoFlota"
              v-model="tipoFlotaSel"
              label="descripcion"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tiposFlota"
              :clearable="false"
            />
          </b-form-group>
          <!-- Placa -->
          <validation-provider
            #default="validationContext"
            name="placa"
            rules="required"
          >
            <b-form-group
              label="Placa"
              label-for="placa"
            >
              <b-form-input
                id="placa"
                v-model="formData.placa"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Marca -->
          <validation-provider
            #default="validationContext"
            name="marca"
            rules="required"
          >
            <b-form-group
              label="Marca"
              label-for="marca"
            >
              <b-form-input
                id="marca"
                v-model="formData.marca"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Modelo -->
          <validation-provider
            #default="validationContext"
            name="modelo"
            rules="required"
          >
            <b-form-group
              label="Modelo"
              label-for="modelo"
            >
              <b-form-input
                id="modelo"
                v-model="formData.modelo"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Capacidad -->
          <b-form-group
            label="Capacidad"
            label-for="capacidad"
          >
            <b-input-group>
              <b-input-group-prepend>
                <v-select
                  v-model="unidadSel"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="unidades"
                  :clearable="false"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="formData.capacidad"
                autofocus
                trim
                placeholder=""
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            label="Conductor"
            label-for="conductor"
          >
            <v-select
              id="conductor"
              v-model="conductorSel"
              label="nombreCompleto"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="conductores"
              :clearable="false"
            />
          </b-form-group>

          <b-form-group
            label="Condición"
            label-for="condicion"
          >
            <v-select
              id="condicion"
              v-model="condicionSel"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="condiciones"
              :clearable="false"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BInputGroup,
    BInputGroupPrepend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebar',
    event: 'update:is-toggle-sidebar',
  },
  props: {
    isToggleSidebar: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const titleForm = ref('')
    const blankData = {
      placa: '',
      modelo: '',
      marca: '',
    }
    const tiposFlota = ref([])
    const tipoFlotaSel = ref({})
    const unidades = ref(['GAL', 'TN'])
    const unidadSel = ref('GAL')
    const conductores = ref([])
    const conductorSel = ref({})
    const condiciones = ref(['PROPIO', 'ALQUILADO'])
    const condicionSel = ref('PROPIO')

    const formData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      formData.value = JSON.parse(JSON.stringify(blankData))
    }

    const getTiposFlota = async () => {
      await store.dispatch('catalogo/TIPO_FLOTA_FIND_ALL', {
        query: '',
        page: -1,
        limit: -1,
        tipo: 'cmb',
        sortBy: 'idTipoFlota%7CASC',
      })
        .then(response => {
          if (response) {
            tiposFlota.value = response
          }
        })
    }

    const getConductores = async () => {
      await store.dispatch('rrhh/TRABAJADOR_FIND_ALL', {
        query: '',
        page: -1,
        limit: -1,
        tipo: 'cmb',
        sortBy: 'nombre%7CASC',
        cargo: 'CHOFER',
      })
        .then(response => {
          if (response) {
            conductores.value = response
          }
        })
    }

    const onSubmit = () => {
      let service = 'inventario/FLOTA_CREATE'
      formData.value.unidadMedida = unidadSel.value
      formData.value.tipoFlota = tipoFlotaSel.value
      formData.value.conductor = conductorSel.value
      formData.value.condicion = condicionSel.value

      if (props.formType === 'edit') {
        service = 'inventario/FLOTA_UPDATE'
      }
      store.dispatch(service, formData.value)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
          emit('update:is-toggle-sidebar', false)
        })
    }

    const toggleSidebar = val => {
      emit('update:is-toggle-sidebar', val)
      getTiposFlota()
      getConductores()
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Flota'
      }
      if (props.formType === 'edit') {
        formData.value = {
          idFlota: props.dataEdit.idFlota,
          placa: props.dataEdit.placa,
          modelo: props.dataEdit.modelo,
          marca: props.dataEdit.marca,
          capacidad: props.dataEdit.capacidad,
          unidadMedida: props.dataEdit.unidadMedida,
          tipoFlota: props.dataEdit.tipoFlota,
          estado: props.dataEdit.estado,
        }
        tipoFlotaSel.value = props.dataEdit.tipoFlota
        unidadSel.value = props.dataEdit.unidadMedida
        conductorSel.value = props.dataEdit.conductor
        titleForm.value = 'Editar Flota'
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      formData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      toggleSidebar,
      titleForm,
      tiposFlota,
      tipoFlotaSel,
      getTiposFlota,
      getConductores,
      unidades,
      unidadSel,
      conductores,
      conductorSel,
      condicionSel,
      condiciones,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#element-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

/* .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 41px);
}

.vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

.vs__search {
  position: absolute;
}

.vs--open .vs__search {
  position: static;
}

.vs__dropdown-option {
  white-space: normal;
} */
</style>
